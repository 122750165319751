import React from "react";
import { Helmet } from 'react-helmet';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const  VerticalInjectionMoldingMachineHK15T150 = () => {


    // const settings = 
    //     {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     arrows: false,
    //     }


    
return (

    <div className=" bg-black">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='The Vertical Injection Molding Machine offers exceptional precision and automation for small plastic parts. Suitable for industries like automotive, electronics, and medical, this machine combines high efficiency and reliability in one compact solution.'
                 />
                <meta name='keywords' 
                      content=' Vertical Injection Molding Machine, Vertical Injection Molding Machine HK15T150, Plastic Injection Molding Machine, Vertical Injection Molding for Precision Parts, High Precision Vertical Injection Molding, Vertical Plastic Molding Machine, Injection Molding Machine for Small Parts, Automatic Vertical Injection Molding Machine, Electric Vertical Injection Molding Machine, Vertical Injection Molding for Plastic Parts, Compact Vertical Injection Molding Machine, 
                                Vertical Injection Molding Machine Manufacturer, Injection Molding Machine for Automotive Components, Injection Molding Machine for Industrial Applications, Vertical Injection Molding for Multi-component Molding, Vertical Molding Machine with High Output, Vertical Injection Molding Machine HK Series, 
                                Vertical Injection Molding with High Precision, Fully Automatic Vertical Injection Molding Machine, Customized Vertical Injection Molding Machine, Injection Molding for Small Plastic Parts, Vertical Molding Machine for Multi-cavity, Reliable Vertical Injection Molding Machine, Energy-efficient Vertical Injection Molding, 
                                Vertical Injection Molding Machine for Complex Molds, Vertical Injection Molding Machine for Plastic Parts, High-Precision Injection Molding Machine, HK15T150 Vertical Molding Machine, Plastic Molding Injection Machine for Small Parts, Injection Molding Machine for Precision Parts, Vertical Plastic Injection Machine HK Series, 
                                Automated Vertical Injection Molding Machine, Energy-Efficient Vertical Injection Molding, Injection Molding Machine for Multi-Cavity Molds, Vertical Molding for Automotive Parts, Small Parts Injection Molding Machine, High-Speed Vertical Injection Molding, Hydraulic Vertical Injection Molding Machine,
                                Precision Vertical Plastic Molding, Custom Vertical Injection Molding Solutions, Vertical Injection Molding Machine for Complex Parts, Compact Vertical Injection Molding Machine, Vertical Injection Molding with Servo Motor, Vertical Injection Molding for Electronic Components, Vertical Injection Molding Machine for Medical Applications,
                                High-Output Vertical Injection Molding Machine, Injection Molding System for Small Plastic Components, Vertical Molding Machine for Low-Volume Production, Vertical Injection Molding with Advanced Automation, Vertical Injection Molding Machine for Multi-Component Parts, Vertical Injection Molding Machine for Small Batch Production,
                                Fully Automatic Vertical Injection Molding Machine, Industrial Vertical Injection Molding Machines, Versatile Vertical Injection Molding Solutions, Advanced Vertical Injection Molding for Plastic Components '        
                />
                <title>Advanced Vertical Injection Molding Machine | High Precision & Automation for Small Parts | Hydroking Industries </title>
                <link rel="canonical" href="https://www.hydrokings.in/Vertical-Injection-Molding-Machine-HK1.5T150" />
            
                
            
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Vertical Injection Molding Machine for Precision Plastic Parts | Hydroking Industries" />
                <meta property="og:description" content="The HK15T150 Vertical Injection Molding Machine offers high-precision molding for small plastic parts with automated operation and energy efficiency. Perfect for automotive, electronics, and industrial applications." />
                
                {/* Open Graph Image for Social Media Sharing */}
                <meta property="og:image" content="images/hydraulic machine  product image 7.jpg" /> {/* Replace with your actual image URL */}
                <meta property="og:image:alt" content="Vertical Injection Molding Machine HK15T150" />
                
                {/* Image Dimensions (Optional, recommended for social media optimization) */}
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                {/* URL for Sharing */}
                <meta property="og:url" content="https://www.hydrokings.in/Vertical-Injection-Molding-Machine-HK1.5T150" /> {/* Replace with your page URL */}
                
                {/* Open Graph Type and Site Name */}
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Hydroking Industries" />

            </Helmet>
       

        <div className=" bg-black relative py-10 ">
          {/* <img class="absolute invisible	 sm:visible top-0 left-0 w-44 md:w-1/6 -scale-x-100 " src="images/circle-triple.svg" alt="" /> */}
            <div className=" mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px]   place-content-center  ">

                <div className="  md:flex  md:justify-between place-content-center  ">
                
                    
                   
                    <div className=" relative grid col-span-1 place-content-center   h-[120px] w-full  md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]   "> 
                         <img class="absolute invisible	 md:visible top-1/6 opacity-50  top-[10%]  md:w-full  " src="images/circle-triple.svg" alt=" background effect" />
                         <img className="absolute invisible	 md:visible  top-0 md:top-0 left-0 transform  " src="images/shadow-ellipse.svg" alt="shadow effect"/>
                                <h1 className=" text-center	 place-content-center text-[#fff] not-italic font-bold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[48px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                    HK1.5T150 Vertical Injection Molding <span className="underlinecss italic ">Machine  </span>     
                                </h1>
                    </div>
                    
                    
                    
                    <div className=" z-50   grid col-span-1   h-[300px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  	 ">
                                     
                         <iframe 
                            className="h-full w-full"
                            src="https://www.youtube.com/embed/DmJvuSLvXk0?si=c_AKkRHjYMoPwMwN" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>
                    
                    </div>
                    
                    

                    {/* <div className=" mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">

                            <div className=" slider-container flex  md:justify-between md:place-content-between  place-content-center   ">
                                <Slider {...settings} className=" place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  ">
                                
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 3.jpg" alt="HK650-HK100 Vertical Injection Molding one"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 3.jpg" alt="HK650-HK100 Vertical Injection Molding two"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 3.jpg" alt="HK650-HK100 Vertical Injection Molding three"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 3.jpg" alt="HK650-HK100 Vertical Injection Molding four"/>
                                     
                                                                   
                                </Slider>
                            </div>
                    </div> */}


                                    <div className=" border mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">
                                        
                                        <Swiper
                                            spaceBetween={20}
                                            centeredSlides={true}
                                            autoplay={{delay: 2500, disableOnInteraction: false,}}
                                            pagination={{clickable: true,}}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className=" mySwiper flex  md:justify-between md:place-content-between  place-content-center "
                                        >                           
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 7.jpg" alt="Vertical Injection Molding HK1.5T150 one"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 7_2.jpg" alt="Vertical Injection Molding HK1.5T150 two"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 7_3.jpg" alt="Vertical Injection Molding HK1.5T150 three"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 7_4.jpg" alt="Vertical Injection Molding HK1.5T150 four"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 7_5.jpg" alt="Vertical Injection Molding HK1.5T150 five"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 7_6.jpg" alt="Vertical Injection Molding HK1.5T150 six"/> </SwiperSlide>
                                        </Swiper>
                                                            
                                    </div>


                </div>
            </div>
        </div>
         


       <div className="bg-black py-10 md:py-20">
                
                    
                
            <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">

                   <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-5 lg:gap-5 xl:gap-10 ">

                        <div className="relative grid col-span-1 md:col-span-2 border p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                            <img className="invisible md:visible absolute top-1/2 right-0 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt="background shadow"/>
                                <h2 className=" text-center	 place-content-center text-[#fff] not-italic font-semibold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                      <span className="underlinecss">Description </span>    
                                </h2>

                                <p className="text-[#fff]  font-medium text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-[30px] pt-8">
                                    The HK1.5T150 Vertical Injection Molding Machine is designed for compact, precise, and high-efficiency production. Its vertical configuration allows for easy mold insertion and removal, optimizing space and reducing floor space requirements. This machine is ideal for producing small to medium-sized plastic components across various industries, such as automotive, consumer electronics, and medical devices.

                                    With its advanced control system, the HK1.5T150 offers consistent injection accuracy, ensuring high-quality output with minimal scrap. The machine is designed for easy operation, reducing the need for extensive operator training while providing a stable and durable platform for continuous, high-performance production. Its robust construction and energy-efficient operation help to reduce operational costs and improve productivity, making it a valuable addition to any modern manufacturing facility.
                                </p>

                        </div>


                        <div className="  relative grid col-span-1 md:col-span-1  border justify-items-center  p-4 md:p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                             {/* <img className="invisible	 md:visible z-100 absolute top-1/2 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=""/> */}
                                <h2 className="mb-5 md:mb-0 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[64px] ">
                                     <span className="underlinecss "> Only Rupee </span> 
                                </h2>
                                <h2 className="mb-5 md:mb-10 mt-0 md:mt-10 text-center	   text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[1px] ">
                                     <span className="">3,00,000 /- </span>
                                </h2>

                                <Link to="tel:+91 8090209080">
                                <button class="z-100 w-[200px] h-13 md:w-[200px] md:h-14 flex gap-3 bg-[#e3242b]  p-[12px_28px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-[red] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300         
                                                 hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                        <img className="-mx-1 w-[28px] h-[28px] " src="images/phone.svg" alt="phone"/>
                                                <span
                                                    
                                                    className="text-[#ffff] tracking-[0.05em] font-semibold text-[20px]"> 
                                                        Book Now
                                                </span>
                                </button>
                                </Link>
                        </div>

                   </div>

            </div>

       </div> 



        <div className="bg-black  py-10">

                <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">
                                <h2 className="mb-5 md:mb-10 lg:mb-20 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[40px] capitalize md:leading-[40px] lg:leading-[10px] ">
                                     <span className="underlinecss "> Product Specification </span> 
                                </h2>
                    <div className="grid grid-cols-1    ">

                        <div className=" grid col-span-1 border ">

                                <div className=" grid  ">
                                            <table className="table text-[#fff]   ">

                                                {/* head */}
                                                <thead className=" ">
                                                    <tr className="  border text-[22px]   ">
                                                        <td className="  text-center  text-[red] font-bold p-2">Sr. No.</td>
                                                        <td className="border text-[red] font-bold p-2">Features Title	</td>
                                                        <td className="border text-[red] font-bold p-2">Features Value</td>
                                                         
                                                    </tr>
                                                </thead>

                                                <tbody  className="">

                                                {/* row 1 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">1.</th>
                                                        <td className="py-2 px-2 border-r border-b">Model Number</td>
                                                        <td className="py-2 px-2 border-b">HK1.5T150</td>
                                                    </tr>

                                                {/* row 2 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">2.</th>
                                                        <td className="py-2 px-2 border-r border-b">Total Wattage kw</td>
                                                        <td className="py-2 px-2 border-b">7.6 three phase</td>  
                                                    </tr>

                                                {/* row 3 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">3.</th>
                                                        <td className="py-2 px-2 border-r border-b">Ejector injection Force</td>
                                                        <td className="py-2 px-2 border-b">3.2 tons</td>
                                                    </tr>

                                                {/* row 4 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">4.</th>
                                                        <td className="py-2 px-2 border-r border-b">Clamping Force</td>
                                                        <td className="py-2 px-2 border-b">55 tons</td>
                                                    </tr>

                                                {/* row 5 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">5.</th>
                                                        <td className="py-2 px-2 border-r border-b">Number Of Temprature heating element</td>
                                                        <td className="py-2 px-2 border-b">3 heating zone</td>  
                                                    </tr>

                                                {/* row 6 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">6.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max. Shot Weight</td>
                                                        <td className="py-2 px-2 border-b">150 gm</td>
                                                    </tr>
                                                    
                                                {/* row 7 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">7.</th>
                                                        <td className="py-2 px-2 border-r border-b">Injection Pressure</td>
                                                        <td className="py-2 px-2 border-b">1500 kg/cm square</td>
                                                    </tr>

                                                {/* row 8 */}
                                                    <tr className="h-full ">
                                                        <th className="py-2  border-r border-b">8.</th>
                                                        <td className="py-2 px-2 border-r border-b">Screw Diameter</td>
                                                        <td className="py-2 px-2 border-b">35 mm</td>  
                                                    </tr>

                                                {/* row 9 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">9.</th>
                                                        <td className="py-2 px-2 border-r border-b">Opening Force</td>
                                                        <td className="py-2 px-2 border-b">2.0 tons</td>
                                                    </tr>

                                                {/* row 10 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">10.</th>
                                                        <td className="py-2 px-2 border-r border-b">Matrial Hopper Capacity</td>
                                                        <td className="py-2 px-2 border-b">15 kg</td>
                                                    </tr>

                                                {/* row 11 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">11.</th>
                                                        <td className="py-2 px-2 border-r border-b">Min.max. Mould Hight</td>
                                                        <td className="py-2 px-2 border-b">100 mm to 300 mm</td>  
                                                    </tr>

                                                {/* row 12 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">12.</th>
                                                        <td className="py-2 px-2 border-r border-b">Opening Stroke</td>
                                                        <td className="py-2 px-2 border-b">300 mm</td>
                                                    </tr>

                                                  
                                                <tr  >
                                                        <th className="py-2 border-r border-b">13.</th>
                                                        <td className="py-2 px-2 border-r border-b">Mould space horizontal</td>
                                                        <td className="py-2 px-2 border-b">600 mm</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">14.</th>
                                                        <td className="py-2 px-2 border-r border-b">Distance between mould and nozzle</td>
                                                        <td className="py-2 px-2 border-b">	19 mm</td>  
                                                    </tr>

                                                 
                                                    <tr>
                                                        <th className="py-2 border-r border-b">15.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.Hydraulic Pressure</td>
                                                        <td className="py-2 px-2 border-b">155 kg/cm square</td>
                                                    </tr>

                                                
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">16.</th>
                                                        <td className="py-2 px-2 border-r border-b">Hydraulic Pump Output</td>
                                                        <td className="py-2 px-2 border-b">19 litter/min.</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">17.</th>
                                                        <td className="py-2 px-2 border-r border-b">Mould Slide Stroke</td>
                                                        <td className="py-2 px-2 border-b">300 MM</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">18.</th>
                                                        <td className="py-2 px-2 border-r border-b">Hydraulic Pump electric Motor</td>
                                                        <td className="py-2 px-2 border-b">	3.5KW (5 horse power)</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">19.</th>
                                                        <td className="py-2 px-2 border-r border-b">Barrel Heating Power</td>
                                                        <td className="py-2 px-2 border-b">3.0 KW</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine Weight Approx</td>
                                                        <td className="py-2 px-2 border-b">1360 kg</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">21.</th>
                                                        <td className="py-2 px-2 border-r border-b">Injection shot Speed Max.</td>
                                                        <td className="py-2 px-2 border-b">0-250 mm</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">22.</th>
                                                        <td className="py-2 px-2 border-r border-b">Oil tank Capacity</td>
                                                        <td className="py-2 px-2 border-b">90 litres</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">23.</th>
                                                        <td className="py-2 px-2 border-r border-b"> Machine Size (LxWxH)</td>
                                                        <td className="py-2 px-2 border-b">1600 x 655 x 2710 MM</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr>
                                                        <th className="py-2 border-r border-b">24.</th>
                                                        <td className="py-2 px-2 border-r border-b">Operation Man Power</td>
                                                        <td className="py-2 px-2 border-b">1 Labour</td>
                                                    </tr>
                                                 
                                                    <tr>
                                                        <th className="py-2 border-r border-b">24.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine Warranty</td>
                                                        <td className="py-2 px-2 border-b">1 Year</td>
                                                    </tr>

                                                    {/* <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">operation requrement</td>
                                                        <td className="py-2 px-2 border-b">single phase 220volt 1kw electricity</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">21.</th>
                                                        <td className="py-2 px-2 border-r border-b">Plunger Diameter</td>
                                                        <td className="py-2 px-2 border-b">40 mm</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Consumption</td>
                                                        <td className="py-2 px-2 border-b">1-2KWh Single Phase</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">21.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Source</td>
                                                        <td className="py-2 px-2 border-b">220volt single phase electricit</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">Product Type</td>
                                                        <td className="py-2 px-2 border-b">injection moulding machine</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r  ">21.</th>
                                                        <td className="py-2 px-2 border-r  ">Production Capacity</td>
                                                        <td className="py-2 px-2  ">15-16kg /Hour</td>
                                                    </tr>  */}

                                                 
                                                </tbody>
                                            </table>
                                </div>

                        </div>

                        <div className=" grid col-span-1 border">


                        </div>

                    </div>

                </div>

        </div>

    










        </div>

    );
}
export default VerticalInjectionMoldingMachineHK15T150;