import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import ContactUs from './screens/ContactUs';
import Gallery from './screens/Gallery';
import Product from './screens/Product';
import Error from './screens/Error';
import TermsAndConditions from './screens/TermsAndConditions';
import Blog from './screens/Blog';
import DieMolds from './screens/DieMolds';
import ChatButton from './screens/ChatButton';

import HorizontalPlasticInjectionMoldingMachine from './screens/Products/HorizontalPlasticInjectionMoldingMachine';
import HK680HK150VerticalInjectionMouldingMachine from './screens/Products/HK680HK150VerticalInjectionMouldingMachine';
import HK650HK100VerticalInjectionMoldingMachine from './screens/Products/HK650HK100VerticalInjectionMoldingMachine';
import VerticalInjectionMoldingMachineHK15T150 from './screens/Products/VerticalInjectionMoldingMachineHK15T150';
import PowerPressMachine from './screens/Products/PowerPressMachine';
import HydraulicStacker from './screens/Products/HydraulicStacker';
import HandPalletTruck from './screens/Products/HandPalletTruck';

import LatheMachine from './screens/ComponentsPages/LatheMachineSolutionPage';
import CNCMachine from './screens/ComponentsPages/CNCMachineSolutionPage';
import VMCMachine from './screens/ComponentsPages/VMCMachineSolutionPage';

import ScrollToTop from "./components/ScrollToTop";
import Header from './components/Header';
import Footer from './components/Footer';
 


function App() {
  return (
     
    <BrowserRouter>
      <ScrollToTop />
       <Header/> 
       <ChatButton/>
       
             
                <Routes>

                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contactUs" element={<ContactUs/>}/>
                    <Route path="/gallery" element={<Gallery/>}/>
                    <Route path="/terms-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/blogs" element={<Blog/>}/>
                    <Route path="/die-mold" element={<DieMolds/>}/>
                    <Route path="/products" element={<Product/>}/>

                    <Route path="/Horizontal-Plastic-Injection-Molding-Machine" element={<HorizontalPlasticInjectionMoldingMachine/>}/>
                    <Route path="/HK680-HK150-Vertical-Injection-Moulding-Machine" element={<HK680HK150VerticalInjectionMouldingMachine/>}/>
                    <Route path="/HK650-HK100-Vertical-Injection-Molding-Machine" element={<HK650HK100VerticalInjectionMoldingMachine/>}/>
                    <Route path="/Vertical-Injection-Molding-Machine-HK1.5T150" element={<VerticalInjectionMoldingMachineHK15T150/>}/>
                    <Route path="/Power-Press-Machine" element={<PowerPressMachine/>}/>
                    <Route path="/Hydraulic-Stacker" element={<HydraulicStacker/>}/>
                    <Route path="/Hand-Pallet-Truck" element={<HandPalletTruck/>}/>

                    <Route path="/Lathe-Machine-Die-Molds" element={<LatheMachine/>}/>
                    <Route path="/CNC-Machine-Die-Molds" element={<CNCMachine/>}/>
                    <Route path="/VMC-Machine-Die-Molds" element={<VMCMachine/>}/>
                    
                    <Route path="*" element={<Error/>}/>

                </Routes>
      <Footer/>  

             

    </BrowserRouter>
   
  );
}

export default App;
