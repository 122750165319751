import React, { Suspense } from 'react'
import {Helmet} from "react-helmet";



const DieMoldPageSection1 = React.lazy(()=> import('./ComponentsPages/DieMoldPageSection1')); 
// const DieMoldProducts = React.lazy(()=> import('./ComponentsPages/DieMoldProducts')); 
const DieMoldPageSection2 = React.lazy(()=> import('./ComponentsPages/DieMoldPageSection2')); 
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));



function DieMold() {
  return (
    <div className='relative  '>  

          <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Our expertise extends to manufacturing all types of industrial molds, including: Injection Moulds, Blow Molding Dies, Injection Moulding Dies, Plastic Die Moulds, Die Casting Moulds, Rotational Molds, Metal Dies, Nylon and PVC Moulds, Silver Injection Mould Dies'              
                />
                <meta name='keywords' 
                      content=' Moulding Dies, Rubber Moulding Die,  blow mold die, Plastic Cap Mould, Plastic Die Mould, Die casting, Die Punch, Graphite dies, Hot runner mold, Injection mould, Plastic Die Mould, Switch Mould, 
                                    Beam Mould, Industrial mould, Carbon Filter Jali Mold Die, RO Inline Mold Die, Plastic Moulding Dies, Aluminium Casting Mold, Plastic Injection Mould Manufacturer, Plastic Molding Die Maker, Aluminium Casting Mold, 
                                    Rubber Die Moulds, Mould Making Service, Hot Runner Injection Mold, Sheet Metal Door Dies, Bakelite Die Maker, Die Casting Mould, '        
                />
                <title>Die-Molds : Comprehensive Solutions for Die & Molds</title>
                <link rel="canonical" href="https://www.hydrokings.in/die-mold" />
          </Helmet>



        <Suspense fallback={<div>Loading...</div>}>

            <DieMoldPageSection1/>
            {/* <DieMoldProducts/> */}
            <DieMoldPageSection2/>
            <HoverImageLinks/>
            
        
        </Suspense>
    </div>
  )
}

export default DieMold;