import React from "react";
// import React, { useRef, useState } from 'react';

import { Helmet } from 'react-helmet';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const  HorizontalPlasticInjectionMoldingMachine = () => {


    // const settings = 
    //     {

    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     arrows: false,
       
    //     };


return (

    <div className=" bg-black">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Discover top-quality horizontal plastic injection molding machines designed for precision, high-speed production, and large-scale manufacturing. Ideal for automotive, electronics, and consumer goods industries. Maximize efficiency and reduce costs with our customizable molding solutions.'
                 />
                <meta name='keywords' 
                      content=' Horizontal Injection Molding Machine, Plastic Injection Molding, Horizontal Molding Machine for Plastics, High-Precision Injection Molding Machines, Plastic Injection Molding Machine Supplier, Industrial Horizontal Injection Molding, Horizontal Molding Equipment for Plastic Parts, Custom Plastic Injection Molding Machines, Horizontal Plastic Molding Systems, 
                                Injection Molding Machine for Large Parts, Horizontal Plastic Molding Machine Manufacturer, Multi-component Injection Molding Machine, Horizontal Plastic Molding for Automotive Parts, Precision Horizontal Injection Molding Machines, High-Speed Horizontal Injection Molding, Horizontal Plastic Molding Equipment, Horizontal Injection Molding Machines for Sale, 
                                Plastic Injection Molding Equipment Supplier, Industrial Horizontal Plastic Molding Machines, Precision Horizontal Injection Molding, Horizontal Molding for Plastic Parts Manufacturing, Automated Horizontal Plastic Injection Machines, Horizontal Injection Molding for High-Volume Production, Custom Horizontal Injection Molding Solutions, 
                                Plastic Molding Machines for Automotive Parts, Horizontal Injection Molding for Electronics Industry, Plastic Injection Molding Technology, Horizontal Injection Molding Process, Horizontal Plastic Molding for Consumer Goods, Horizontal Injection Molding Machinery Manufacturer, High-Speed Horizontal Injection Molding Machines, Horizontal Injection Molding Systems for Industrial Use,
                                Plastic Injection Molding Machines for Large Parts, Horizontal Injection Molding Machine Cost, Horizontal Molding for Complex Plastic Parts, High-Quality Horizontal Injection Molding Machines, Plastic Injection Molding Equipment for Small Parts, Horizontal Injection Molding for Precision Manufacturing, Horizontal Plastic Molding Machines for Prototyping,
                                Horizontal Injection Molding Machine with Servo Motors '        
                />
                <title>High-Precision Horizontal Plastic Injection Molding Machines for Efficient Production | Hydroking Industries</title>
                <link rel="canonical" href="https://www.hydrokings.in//Horizontal-Plastic-Injection-Molding-Machine" />
            
            
                    {/* Open Graph (OG) Meta Tags */}
                    <meta property="og:title" content="High-Precision Horizontal Plastic Injection Molding Machines for Efficient Production | Hydroking Industries" />
                    <meta property="og:description" content="Explore horizontal plastic injection molding machines built for high-precision, high-speed production in industries like automotive, electronics, and consumer goods. Customizable and cost-effective molding solutions." />
                    
                    {/* Open Graph Image for Social Media Sharing */}
                    <meta property="og:image" content="images/hydraulic machine  product image 1.jpg" /> {/* Replace with your actual image URL */}
                    <meta property="og:image:alt" content="Horizontal Plastic Injection Molding Machine" />
                    
                    {/* Image Dimensions (Optional, but recommended for better social media presentation) */}
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />

                    {/* URL for Sharing */}
                    <meta property="og:url" content="https://www.hydrokings.in//Horizontal-Plastic-Injection-Molding-Machine" /> {/* Replace with your page URL */}
                    
                    {/* Open Graph Type and Site Name */}
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Hydroking Industries" />

            
            
            </Helmet>
       

        <div className=" bg-black relative py-10 ">
          {/* <img class="absolute invisible	 sm:visible top-0 left-0 w-44 md:w-1/6 -scale-x-100 " src="images/circle-triple.svg" alt="" /> */}
            <div className=" mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px]   place-content-center  ">

                <div className="  md:flex  md:justify-between place-content-center  ">
                
                    
                   
                    <div className=" relative grid col-span-1 place-content-center   h-[120px] w-full  md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]   "> 
                         <img class="absolute invisible	 md:visible top-1/6 opacity-50  top-[10%]  md:w-full  " src="images/circle-triple.svg" alt=" circle triple background" />
                         <img className="absolute invisible	 md:visible  top-0 md:top-0 left-0 transform  " src="images/shadow-ellipse.svg" alt=" shadow background "/>
                                <h1 className=" text-center	 place-content-center text-[#fff] not-italic font-bold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[48px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                    Horizontal Plastic Injection Molding <span className="underlinecss italic ">Machine  </span>     
                                </h1>
                    </div>
                    
                    
                    
                    <div className=" z-50   grid col-span-1   h-[300px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  	 ">
                                     
                         <iframe 
                            className="h-full w-full"
                            src="https://www.youtube.com/embed/Zav9PZyOLRY?si=1AVIIix2qAi6hnTF" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>
                    
                    </div>
                    
                    

                    <div className=" border mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">

                         {/* slider-container   <div className="  flex  md:justify-between md:place-content-between  place-content-center   "> */}
                            {/* <Slider {...settings} className=" place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  ">
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 1.jpg" alt="Horizontal Plastic Injection Molding Machine one"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 1.jpg" alt="Horizontal Plastic Injection Molding Machine two"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 1.jpg" alt="Horizontal Plastic Injection Molding Machine three"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 1.jpg" alt="Horizontal Plastic Injection Molding Machine four"/>                                 
                            </Slider> */}


                                    <Swiper
                                        spaceBetween={20}
                                        centeredSlides={true}
                                        autoplay={{delay: 2500, disableOnInteraction: false,}}
                                        pagination={{clickable: true,}}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className=" mySwiper flex  md:justify-between md:place-content-between  place-content-center "
                                    >

                                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "><img className="h-full w-full" src="images/hydraulic machine  product image 1.jpg" alt="Horizontal Plastic Injection Molding Machine one"/></SwiperSlide>
                                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "><img className="h-full w-full" src="images/hydraulic machine  product image 1_2.jpg" alt="Horizontal Plastic Injection Molding Machine two"/></SwiperSlide>
                                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "><img className="h-full w-full" src="images/hydraulic machine  product image 1_3.jpg" alt="Horizontal Plastic Injection Molding Machine three"/></SwiperSlide>
                                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "><img className="h-full w-full" src="images/hydraulic machine  product image 1_4.jpg" alt="Horizontal Plastic Injection Molding Machine four"/></SwiperSlide>
                                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "><img className="h-full w-full" src="images/hydraulic machine  product image 1_5.jpg" alt="Horizontal Plastic Injection Molding Machine five"/></SwiperSlide>

                                    </Swiper>


                            {/* </div> */}
                    </div>


                </div>
            </div>
        </div>
         


       <div className="bg-black py-10 md:py-20">
                
                    
                
            <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">

                   <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-5 lg:gap-5 xl:gap-10 ">

                        <div className="relative grid col-span-1 md:col-span-2 border p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                            <img className="invisible md:visible absolute top-1/2 right-0 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=" shadow "/>
                                <h2 className=" text-center	 place-content-center text-[#fff] not-italic font-semibold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                      <span className="underlinecss">Description </span>    
                                </h2>

                                <p className="text-[#fff]  font-medium text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-[30px] pt-8">
                                    Our Horizontal Molding Machine is designed to deliver efficient and precise 
                                    manufacturing for a variety of products. With its advanced technology and user-friendly
                                    interface, this machine is perfect for a range of industrial applications. 
                                    Its horizontal design allows for easy access and maintenance, making it a reliable 
                                    and practical solution for your molding needs.
                                </p>

                        </div>


                        <div className="  relative grid col-span-1 md:col-span-1  border justify-items-center  p-4 md:p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                             {/* <img className="invisible	 md:visible z-100 absolute top-1/2 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=""/> */}
                                <h2 className="mb-5 md:mb-0 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[64px] ">
                                     <span className="underlinecss "> Only Rupee </span> 
                                </h2>
                                <h2 className="mb-5 md:mb-10 mt-0 md:mt-10 text-center	   text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[1px] ">
                                     <span className="">11,00,000 /- </span>
                                </h2>

                                <Link to="tel:+91 8090209080">
                                <button class="z-100 w-[200px] h-13 md:w-[200px] md:h-14 flex gap-3 bg-[#e3242b]  p-[12px_28px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-[red] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300         
                                                 hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                        <img className="-mx-1 w-[28px] h-[28px] " src="images/phone.svg" alt="phone"/>
                                                <span
                                                    
                                                    className="text-[#ffff] tracking-[0.05em] font-semibold text-[20px]"> 
                                                        Book Now
                                                </span>
                                </button>
                                </Link>
                        </div>

                   </div>

            </div>

       </div> 



        <div className="bg-black  py-10">

                <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">
                                <h2 className="mb-5 md:mb-10 lg:mb-20 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[40px] capitalize md:leading-[40px] lg:leading-[10px] ">
                                     <span className="underlinecss "> Product Specification </span> 
                                </h2>
                    <div className="grid grid-cols-1    ">

                        <div className=" grid col-span-1 border ">

                                <div className=" grid  ">
                                            <table className="table text-[#fff]   ">

                                                {/* head */}
                                                <thead className=" ">
                                                    <tr className="  border text-[22px]   ">
                                                        <td className="  text-center  text-[red] font-bold p-2">Sr. No.</td>
                                                        <td className="border text-[red] font-bold p-2">Features Title	</td>
                                                        <td className="border text-[red] font-bold p-2">Features Value</td>
                                                         
                                                    </tr>
                                                </thead>

                                                <tbody  className="">

                                                {/* row 1 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">1.</th>
                                                        <td className="py-2 px-2 border-r border-b">Operation Mode</td>
                                                        <td className="py-2 px-2 border-b">Automatic & Semi automatic</td>
                                                    </tr>

                                                {/* row 2 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">2.</th>
                                                        <td className="py-2 px-2 border-r border-b">Brand</td>
                                                        <td className="py-2 px-2 border-b">Hydroking Industries</td>  
                                                    </tr>

                                                {/* row 3 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">3.</th>
                                                        <td className="py-2 px-2 border-r border-b">Model</td>
                                                        <td className="py-2 px-2 border-b">4800HK250</td>
                                                    </tr>

                                                {/* row 4 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">4.</th>
                                                        <td className="py-2 px-2 border-r border-b">Capacity</td>
                                                        <td className="py-2 px-2 border-b">7-12Tons Per Day(10-12HOURS)</td>
                                                    </tr>

                                                {/* row 5 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">5.</th>
                                                        <td className="py-2 px-2 border-r border-b">Clamping Way</td>
                                                        <td className="py-2 px-2 border-b">Hydraulic</td>  
                                                    </tr>

                                                {/* row 6 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">6.</th>
                                                        <td className="py-2 px-2 border-r border-b">Warranty</td>
                                                        <td className="py-2 px-2 border-b">12Months (one year) </td>
                                                    </tr>
                                                    
                                                {/* row 7 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">7.</th>
                                                        <td className="py-2 px-2 border-r border-b">Clamping force</td>
                                                        <td className="py-2 px-2 border-b">Standerd</td>
                                                    </tr>

                                                {/* row 8 */}
                                                    <tr className="h-full ">
                                                        <th className="py-2  border-r border-b">8.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine Weight</td>
                                                        <td className="py-2 px-2 border-b">4.8Ton (Approx)</td>  
                                                    </tr>

                                                {/* row 9 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">9.</th>
                                                        <td className="py-2 px-2 border-r border-b">Plastic Type</td>
                                                        <td className="py-2 px-2 border-b">HDPE,PP,ABS,PE, PET, HDPE, LDPE, FIRST & SECOND GRADE ROW MATERIAL ETC.</td>
                                                    </tr>

                                                {/* row 10 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">10.</th>
                                                        <td className="py-2 px-2 border-r border-b">Structure</td>
                                                        <td className="py-2 px-2 border-b">Horizontal Moulding Machines</td>
                                                    </tr>

                                                {/* row 11 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">11.</th>
                                                        <td className="py-2 px-2 border-r border-b">Oil Tank Capacity</td>
                                                        <td className="py-2 px-2 border-b">257 Liter</td>  
                                                    </tr>

                                                {/* row 12 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">12.</th>
                                                        <td className="py-2 px-2 border-r border-b">Number Of Phases</td>
                                                        <td className="py-2 px-2 border-b">Three Phase</td>
                                                    </tr>

                                                {/* row 13 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">13.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power</td>
                                                        <td className="py-2 px-2 border-b">5 KW</td>
                                                    </tr>

                                                {/* row 14 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">14.</th>
                                                        <td className="py-2 px-2 border-r border-b">Voltage</td>
                                                        <td className="py-2 px-2 border-b">440 V</td>  
                                                    </tr>

                                                {/* row 15 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">15.</th>
                                                        <td className="py-2 px-2 border-r border-b">Die Mould Size Dimention</td>
                                                        <td className="py-2 px-2 border-b">430 x 430 mm</td>
                                                    </tr>

                                                
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">16.</th>
                                                        <td className="py-2 px-2 border-r border-b">Opening Stroke</td>
                                                        <td className="py-2 px-2 border-b">400-900 mm</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">17.</th>
                                                        <td className="py-2 px-2 border-r border-b">Stroke Volume (Per Shot) </td>
                                                        <td className="py-2 px-2 border-b">250 gm</td>  
                                                    </tr>
                                                
                                                 
                                                    {/* <tr  >
                                                        <th className="py-2 border-r border-b">18.</th>
                                                        <td className="py-2 px-2 border-r border-b">Electricity Bill</td>
                                                        <td className="py-2 px-2 border-b">10₹/10000 pipe cutting</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r">19.</th>
                                                        <td className="py-2 px-2 border-r">Machine Weight</td>
                                                        <td className="py-2 px-2">40kg Apx</td>  
                                                    </tr>  */}

                                                 
                                                </tbody>
                                            </table>
                                </div>

                        </div>

                        <div className=" grid col-span-1 border">


                        </div>

                    </div>

                </div>

        </div>

    










        </div>

    );
}
export default HorizontalPlasticInjectionMoldingMachine;