import React from "react";
import { Helmet } from 'react-helmet';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const PowerPressMachine = () => {


    // const settings = 
    //     {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     arrows: false,
    //     }


return (

    <div className=" bg-black">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Discover our advanced power press machines designed for precision metal forming, bending, and industrial manufacturing. With both hydraulic and mechanical options, our machines deliver high performance, durability, and efficiency for various applications including automotive, sheet metal, and heavy-duty tasks'
                 />
                <meta name='keywords' 
                      content=' Power Press Machine, Hydraulic Power Press, Mechanical Power Press, Industrial Power Press Machine, Power Press for Sheet Metal, Heavy Duty Power Press, High-Precision Power Press, Automatic Power Press Machine, Manual Power Press, C-Type Power Press Machine,
                                High-Speed Power Press, Power Press for Metal Forming, Power Press for Manufacturing, Power Press Machine for Pressing, Heavy Load Power Press, Electric Power Press Machine, Industrial Pressing Equipment, Precision Power Press Machine, Power Press for Automotive Industry,
                                Servo Power Press, Mechanical Press for Heavy Applications, High Capacity Power Press Machine, Power Press Machine for Sheet Metal Forming, Power Press for Cutting and Bending, CNC Power Press Machine, Automatic Power Press for Metal Forming, High-Speed Hydraulic Press Machine,
                                Precision Power Press for Manufacturing, Mechanical Press Machine for Industrial Use, Power Press for Bending and Cutting, Heavy-Duty Industrial Power Press, Hydraulic Press Machine for Sheet Metal, Power Press for Automotive Parts Manufacturing, Electric Power Press for Manufacturing, 
                                Servo Motor Power Press Machine, Four-Column Power Press Machine, Power Press for Punching, Sheet Metal Power Press Machine, High-Capacity Press Machine, Hydraulic Press for Forming Operations, Power Press Machine with Automatic Feed, Heavy Load Pressing Machine, Deep Drawing Power Press,
                                Power Press for Stamping Operations, Precision Hydraulic Power Press, Custom Power Press Machine, Power Press for Metal Sheet Processing, Industrial Hydraulic Press Machine, Power Press for Punch Press Applications, Compact Power Press Machine, Power Press Machine for High Volume Production, 
                                Power Press for Metal Fabrication, Hydraulic Power Press for Heavy Pressing, Multi-Function Power Press Machine, Programmable Power Press Machine, Power Press for Electrical Component Manufacturing, Power Press for Cold Forming, Steel Sheet Power Press Machine, High-Performance Power Press Machine '        
                />
                <title>High-Performance Power Press Machine for Metal Forming, Bending & Manufacturing | Hydroking Industries</title>
                <link rel="canonical" href="https://www.hydrokings.in/Power-Press-Machine" />

                

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content="High-Performance Power Press Machine for Metal Forming & Manufacturing | Hydroking Industries" />
                    <meta property="og:description" content="Discover advanced power press machines for metal forming, bending, and industrial manufacturing. Precision, durability, and high efficiency for a variety of applications." />
                    
                    {/* Open Graph Image for Social Media Sharing */}
                    <meta property="og:image" content="images/hydraulic machine  product image 4.jpg" /> {/* Replace with your actual image URL */}
                    <meta property="og:image:alt" content="High-Performance Power Press Machine for Metal Forming" />
                    
                    {/* Image Dimensions (Optional, recommended for social media optimization) */}
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />

                    {/* URL for Sharing */}
                    <meta property="og:url" content="https://www.hydrokings.in/Power-Press-Machine" /> {/* Replace with your page URL */}
                    
                    {/* Open Graph Type and Site Name */}
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Hydroking Industries" />
            </Helmet>
       

        <div className=" bg-black relative py-10 ">
          {/* <img class="absolute invisible	 sm:visible top-0 left-0 w-44 md:w-1/6 -scale-x-100 " src="images/circle-triple.svg" alt="" /> */}
            <div className=" mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px]   place-content-center  ">

                <div className="  md:flex  md:justify-between place-content-center  ">
                
                    
                   
                    <div className=" relative grid col-span-1 place-content-center   h-[120px] w-full  md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]   "> 
                         <img class="absolute invisible	 md:visible top-1/6 opacity-50  top-[10%]  md:w-full  " src="images/circle-triple.svg" alt="circle background " />
                         <img className="absolute invisible	 md:visible  top-0 md:top-0 left-0 transform  " src="images/shadow-ellipse.svg" alt="shadow backgound "/>
                                <h1 className=" text-center	 place-content-center text-[#fff] not-italic font-bold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[48px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                Power Press <span className="underlinecss italic ">Machine  </span>     
                                </h1>
                    </div>
                    
                    
                    
                    <div className=" z-50   grid col-span-1   h-[300px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  	 ">
                                     
                         <iframe 
                            className="h-full w-full"
                            src="https://www.youtube.com/embed/Zav9PZyOLRY?si=1AVIIix2qAi6hnTF" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>
                    
                    </div>
                    
                    

                    {/* <div className=" mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">

                            <div className=" slider-container flex  md:justify-between md:place-content-between  place-content-center   ">
                                <Slider {...settings} className=" place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  ">
                                
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 4.jpg" alt="Power Press machine  one"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 4.jpg" alt="Power Press machine  two"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 4.jpg" alt="Power Press machine  three"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 4.jpg" alt="Power Press machine  four"/>
                                     
                                                                   
                                </Slider>
                            </div>
                    </div> */}


                                    <div className=" border mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">
                                        
                                        <Swiper
                                            spaceBetween={20}
                                            centeredSlides={true}
                                            autoplay={{delay: 2500, disableOnInteraction: false,}}
                                            pagination={{clickable: true,}}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className=" mySwiper flex  md:justify-between md:place-content-between  place-content-center "
                                        >                           
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 4.jpg" alt="Power Press machine  one"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 4_2.jpg" alt="Power Press machine  two"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 4_3.jpg" alt="Power Press machine  three"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 4_4.jpg" alt="Power Press machine  four"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 4_5.jpg" alt="Power Press machine  five"/> </SwiperSlide>
                                        </Swiper>
                                                            
                                    </div>

                </div>
            </div>
        </div>
         


       <div className="bg-black py-10 md:py-20">
                
                    
                
            <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">

                   <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-5 lg:gap-5 xl:gap-10 ">

                        <div className="relative grid col-span-1 md:col-span-2 border p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                            <img className="invisible md:visible absolute top-1/2 right-0 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt="shadow-ellipse backgound "/>
                                <h2 className=" text-center	 place-content-center text-[#fff] not-italic font-semibold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                      <span className="underlinecss">Description </span>    
                                </h2>

                                <p className="text-[#fff]  font-medium text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-[30px] pt-8">
                                    The Power Press Machine is designed for robust and efficient metal forming and shaping operations. With its high pressing force and precision engineering, this machine excels in tasks such as punching, bending, and stamping a variety of materials.

                                    Equipped with a user-friendly control panel, it allows operators to easily adjust settings for optimal performance. The sturdy construction ensures durability and stability during operation, making it suitable for heavy-duty applications in industries like automotive, manufacturing, and metal fabrication.

                                    The Power Press Machine enhances productivity by delivering consistent results while minimizing downtime. Its versatility makes it an essential tool for any workshop looking to achieve high-quality metalworking outcomes.
                                </p>

                        </div>


                        <div className="  relative grid col-span-1 md:col-span-1  border justify-items-center  p-4 md:p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                             {/* <img className="invisible	 md:visible z-100 absolute top-1/2 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=""/> */}
                                <h2 className="mb-5 md:mb-0 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[64px] ">
                                     <span className="underlinecss "> Only Rupee </span> 
                                </h2>
                                <h2 className="mb-5 md:mb-10 mt-0 md:mt-10 text-center	   text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[1px] ">
                                     <span className="">4,50,000 /- </span>
                                </h2>

                                <Link to="tel:+91 8090209080">
                                <button class="z-100 w-[200px] h-13 md:w-[200px] md:h-14 flex gap-3 bg-[#e3242b]  p-[12px_28px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-[red] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300         
                                                 hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                        <img className="-mx-1 w-[28px] h-[28px] " src="images/phone.svg" alt="phone"/>
                                                <span className="text-[#ffff] tracking-[0.05em] font-semibold text-[20px]"> 
                                                    Book Now
                                                </span>
                                </button>
                                </Link>
                        </div>

                   </div>

            </div>

       </div> 



        <div className="bg-black  py-10">

                <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">
                                <h2 className="mb-5 md:mb-10 lg:mb-20 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[40px] capitalize md:leading-[40px] lg:leading-[10px] ">
                                     <span className="underlinecss "> Product Specification </span> 
                                </h2>
                    <div className="grid grid-cols-1    ">

                        <div className=" grid col-span-1 border ">

                                <div className=" grid  ">
                                            <table className="table text-[#fff]   ">

                                                {/* head */}
                                                <thead className=" ">

                                                    <tr className="  border text-[22px]   ">
                                                        <td className="  text-center  text-[red] font-bold p-2">Sr. No.</td>
                                                        <td className="border text-[red] font-bold p-2">Features Title	</td>
                                                        <td className="border text-[red] font-bold p-2">Features Value</td>
                                                    </tr>
                                                </thead>

                                                <tbody  className="">

                                                {/* row 1 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">1.</th>
                                                        <td className="py-2 px-2 border-r border-b">Brand</td>
                                                        <td className="py-2 px-2 border-b">Hydroking Industries </td>
                                                    </tr>

                                                {/* row 2 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">2.</th>
                                                        <td className="py-2 px-2 border-r border-b">Capacity</td>
                                                        <td className="py-2 px-2 border-b">	25 Ton</td>  
                                                    </tr>

                                                {/* row 3 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">3.</th>
                                                        <td className="py-2 px-2 border-r border-b">Voltage</td>
                                                        <td className="py-2 px-2 border-b">220 V</td>
                                                    </tr>

                                                {/* row 4 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">4.</th>
                                                        <td className="py-2 px-2 border-r border-b">Type Of Press</td>
                                                        <td className="py-2 px-2 border-b">H Frame Press</td>
                                                    </tr>

                                                {/* row 5 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">5.</th>
                                                        <td className="py-2 px-2 border-r border-b">Return Force (kN)</td>
                                                        <td className="py-2 px-2 border-b">500</td>  
                                                    </tr>

                                                {/* row 6 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">6.</th>
                                                        <td className="py-2 px-2 border-r border-b">Load</td>
                                                        <td className="py-2 px-2 border-b">	20 Ton </td>
                                                    </tr>
                                                    
                                                {/* row 7 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">7.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Source</td>
                                                        <td className="py-2 px-2 border-b">Hydraulic</td>
                                                    </tr>

                                                {/* row 8 */}
                                                    <tr className="h-full ">
                                                        <th className="py-2  border-r border-b">8.</th>
                                                        <td className="py-2 px-2 border-r border-b">Automation Grade</td>
                                                        <td className="py-2 px-2 border-b">Semi Automatic</td>  
                                                    </tr>

                                                {/* row 9 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">9.</th>
                                                        <td className="py-2 px-2 border-r border-b">Material</td>
                                                        <td className="py-2 px-2 border-b">Mild Steel</td>
                                                    </tr>

                                                {/* row 10 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">10.</th>
                                                        <td className="py-2 px-2 border-r border-b">Model Name/Number</td>
                                                        <td className="py-2 px-2 border-b">1800HK15000</td>
                                                    </tr>

                                                {/* row 11 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">11.</th>
                                                        <td className="py-2 px-2 border-r border-b">Type</td>
                                                        <td className="py-2 px-2 border-b">Motorised H Frame Hydraulic Workshop Press</td>  
                                                    </tr>

                                                {/* row 12 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">12.</th>
                                                        <td className="py-2 px-2 border-r border-b">Capacity</td>
                                                        <td className="py-2 px-2 border-b">25 Ton</td>
                                                    </tr>

                                                {/* row 13 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">13.</th>
                                                        <td className="py-2 px-2 border-r border-b">Material</td>
                                                        <td className="py-2 px-2 border-b">Mild Steel</td>
                                                    </tr>

                                                {/* row 14 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">14.</th>
                                                        <td className="py-2 px-2 border-r border-b">Automation Grade</td>
                                                        <td className="py-2 px-2 border-b">Semi Automatic</td>  
                                                    </tr>

                                                {/* row 15 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">15.</th>
                                                        <td className="py-2 px-2 border-r border-b">Control Type</td>
                                                        <td className="py-2 px-2 border-b"> Non-CNC</td>
                                                    </tr>

                                                
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">16.</th>
                                                        <td className="py-2 px-2 border-r border-b">Bed Size</td>
                                                        <td className="py-2 px-2 border-b">920 x 350 mm (LR x FB)</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">17.</th>
                                                        <td className="py-2 px-2 border-r border-b">Color</td>
                                                        <td className="py-2 px-2 border-b">Blue, Orange, Red (Custom color options available)</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">18.</th>
                                                        <td className="py-2 px-2 border-r border-b">Frame Type</td>
                                                        <td className="py-2 px-2 border-b">H Frame</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">19.</th>
                                                        <td className="py-2 px-2 border-r border-b">Day Light Gap</td>
                                                        <td className="py-2 px-2 border-b">970 mm</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">Distance Between Columns</td>
                                                        <td className="py-2 px-2 border-b">850 x 300 mm (LR x FB)</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">21.</th>
                                                        <td className="py-2 px-2 border-r border-b">Electric Motor Power</td>
                                                        <td className="py-2 px-2 border-b">3 HP Godrej Motor</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">22.</th>
                                                        <td className="py-2 px-2 border-r border-b">Space Between Columns</td>
                                                        <td className="py-2 px-2 border-b">850 x 300 mm (LR x FB)</td>  
                                                    </tr> 

{/* 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">23.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.Mold Thickness Capacity (LxTxH)</td>
                                                        <td className="py-2 px-2 border-b">253 mm</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">24.</th>
                                                        <td className="py-2 px-2 border-r border-b">Operation Man Power</td>
                                                        <td className="py-2 px-2 border-b">1 Labour</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">25.</th>
                                                        <td className="py-2 px-2 border-r border-b">operation requrement</td>
                                                        <td className="py-2 px-2 border-b">single phase 220volt 1kw electricity</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">26.</th>
                                                        <td className="py-2 px-2 border-r border-b">Plunger Diameter</td>
                                                        <td className="py-2 px-2 border-b">40 mm</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">27.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Consumption</td>
                                                        <td className="py-2 px-2 border-b">1-2KWh Single Phase</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">28.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Source</td>
                                                        <td className="py-2 px-2 border-b">220volt single phase electricit</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">29.</th>
                                                        <td className="py-2 px-2 border-r border-b">Product Type</td>
                                                        <td className="py-2 px-2 border-b">injection moulding machine</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r  ">30.</th>
                                                        <td className="py-2 px-2 border-r  ">Production Capacity</td>
                                                        <td className="py-2 px-2  ">15-16kg /Hour</td>
                                                    </tr>
 */}
                                                 
                                                </tbody>
                                            </table>
                                </div>

                        </div>

                        <div className=" grid col-span-1 border">


                        </div>

                    </div>

                </div>

        </div>

    










        </div>

    );
}
export default PowerPressMachine;