import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
 

const AboutBanner = React.lazy(()=> import('../screens/ComponentsPages/AboutBanner'));
const AboutInfo = React.lazy(()=> import('../screens/ComponentsPages/AboutOurCompanyInfo'));
const AboutRange = React.lazy(()=> import('../screens/ComponentsPages/AboutRange'));
const ScrillingImages = React.lazy(()=> import('../screens/ComponentsPages/ScrillingImages'));
const HReview = React.lazy(()=> import('./ComponentsPages/HomeReviewPeople')); 
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));
const WavyBackgroundPage = React.lazy(()=> import('./ComponentsPages/HomeCallInfo')); 
const AboutCertificateImage = React.lazy(()=> import('./ComponentsPages/AboutCertificateImage')); 

 

const About  = () => {
    return (
        <div className='relative'> 

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Established in 2000, Hydroking Industries has emerged as a leading provider of advanced hydraulic machines and comprehensive industrial mold solutions. With 10 years of experience in the industry, we have garnered a reputation for excellence, innovation, and customer satisfaction.
                               Since inception, Hydroking Industries has been committed to pushing the boundaries of hydraulic technology. We began with a vision to deliver high-quality, precision-engineered hydraulic machines that meet the rigorous demands of modern industrial applications.'
                               
                />
                <meta name='keywords' 
                      content=' Hydraulic Machine, CNC Die Molds, VMC Die Molds, Injection Molding Machine, Molding Machine, Moulding, Moulding Machine, Mold, Die, Vertical Injection Molding Machine, Horizontal Injection Molding Machine, Blow Molding Machine, 
                                CNC Machine, VMC Machine, Cup Making Machine, Plate Making Machine, Manufacturer, Hydroking, Hydroking Industries, Plastic Injection Machine, Plastic Machine, Toy Making Machine, Plastic Machine, Plastic Molding Machine, 
                                cnc job work, vmc job work,Horizontal Plastic Injection Molding Machine, Hydraulic Stacker, Hand Pallet Truck, Power Press Machine, Hand Plate Machine, Stacker Machine, Hydraulic Vertical Injection Molding Machine, Plastic Vertical Injection Machine,
                                Vertical Injection Moulding Machine Manufacturer, Vertical Injection Moulding Machine Price, Automatic Vertical Injection Molding Machine, Small Vertical Injection Molding Machine, Electric Vertical Injection Molding Machine, Best Vertical Injection Molding Machine in India,
                                 Cheap Vertical Injection Molding Machine for Sale, Fully Automatic Vertical Injection Molding Machine, Vertical Injection Molding Machine for PVC Products, Low-Cost Vertical Injection Moulding Machine, Vertical Plastic Injection Machine with PLC Control, 
                                 Vertical Injection Machine for Rubber and Silicone, Vertical Injection Machine for Automotive Parts, Vertical Injection Machine for Medical Equipment, Vertical Injection Molding for Electrical Components, Vertical Injection Molding for Mobile Charger Parts, Vertical Injection Machine for LED Bulb Holder,
                                 junction box making injection moulding machine, Bucket Making Machine, Electrical junction box making injection moulding machine  '        
                />
                <title>About Page : ONE STOP SOLUTION FOR ALL HYDRAULIC MACHINERY NEEDS</title>
                <link rel="canonical" href="https://www.hydrokings.in/about" />
            </Helmet>



            <Suspense fallback={<div>Loading...</div>}>
                 
                   
                {/* <AboutPageSection1/>
                <AboutPageSection2/>
                <HomepageSectionFive/> 
                <AboutCallPage/> */}
                 
                    <AboutBanner/>
                    <AboutInfo/>
                    <ScrillingImages/>
                    <AboutCertificateImage/>
                    <AboutRange/>
                    <HoverImageLinks/>
                    <HReview/>
                    <WavyBackgroundPage/>


                
            </Suspense>
        </div>

        );
    }
export default About