import React from "react";
import { Helmet } from 'react-helmet';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const  HK680HK150VerticalInjectionMouldingMachine = () => {


    
    // const settings = 
    //     {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     arrows: false,
    //     }


return (

    <div className=" bg-black">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Find advanced vertical injection molding systems designed for automotive and electronics industries. High-speed, cost-effective solutions for producing precision plastic parts.'
                />
                
                <meta name='keywords' 
                      content=' Vertical Injection Molding Technology, Vertical Injection Molding for High-Precision Parts, Vertical Molding Machine for Plastic Parts, Vertical Injection Molding Machine for Small-Batch Production, Vertical Injection Molding for Multi-Material Molding, Insert Molding Vertical Injection Machines, Vertical Injection Molding for Complex Plastic Parts, 
                                High-Quality Vertical Injection Molding Solutions, Custom Vertical Injection Molding Machines for Electronics, Affordable Vertical Injection Molding Equipment, Vertical Molding for Medical Device Manufacturing, Precision Vertical Injection Molding for Automotive Parts, Automated Vertical Injection Molding Machines, Vertical Injection Molding for Thin-Walled Components,
                                Fast Vertical Injection Molding Systems, Vertical Molding Machines for Consumer Goods, Energy-Efficient Vertical Injection Molding Machines, Vertical Injection Molding Equipment for Prototyping, Vertical Injection Molding Machine with High-Speed Clamping, Advanced Vertical Injection Molding Systems for Small Parts, Vertical Injection Molding Machines for Automotive Components,
                                Medical Grade Vertical Injection Molding Machines, Vertical Injection Molding for High-Volume Production, Vertical Plastic Molding Machines for Electronics Parts, Vertical Injection Molding for Precision Molding of Small Parts, Best Vertical Injection Molding Machines for Insert Molding, Custom Vertical Molding Machines for Complex Manufacturing Needs '        
                />
                <title> HK680-HK150 Vertical Injection Moulding Machine | Hydroking Industries</title>
                <link rel="canonical" href="https://www.hydrokings.in/HK680-HK150-Vertical-Injection-Moulding-Machine" />

              
              
                {/* Open Graph (OG) Meta Tags */}
                    <meta property="og:title" content="Precision Vertical Injection Molding Machines for Automotive & Electronics | Hydroking Industries" />
                    <meta property="og:description" content="Find high-quality vertical injection molding machines designed for industries like automotive, electronics, and consumer goods. Perfect for small parts, precision molding, and insert molding." />
                    
                      {/* Image for Social Media   */}
                    <meta property="og:image" content="images/hydraulic machine  product image 2.jpg" /> {/* Replace with your image URL */}
                    <meta property="og:image:alt" content="Vertical Injection Molding Machine " />
                    
                    {/* <!-- Image Dimensions (Optional but Recommended) --> */}
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />

                    {/* <!-- Website URL --> */}
                    <meta property="og:url" content="https://www.hydrokings.in/HK680-HK150-Vertical-Injection-Moulding-Machine" /> {/* Replace with the correct URL */}

                    {/* <!-- Content Type (Usually Website) --> */}
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Hydroking Industries" />

                    {/* <!-- Locale (Optional) --> */}
                    <meta property="og:locale" content="en_US" />  {/* Use the appropriate language code if needed */}
                    
                    
            </Helmet>
       

        <div className=" bg-black relative py-10 ">
          {/* <img class="absolute invisible	 sm:visible top-0 left-0 w-44 md:w-1/6 -scale-x-100 " src="images/circle-triple.svg" alt="" /> */}
            <div className=" mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px]   place-content-center  ">

                <div className="  md:flex  md:justify-between place-content-center  ">
                
                    
                   
                    <div className=" relative grid col-span-1 place-content-center   h-[120px] w-full  md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]   "> 
                         <img class="absolute invisible	 md:visible top-1/6 opacity-50  top-[10%]  md:w-full  " src="images/circle-triple.svg" alt="circle triple background" />
                         <img className="absolute invisible	 md:visible  top-0 md:top-0 left-0 transform  " src="images/shadow-ellipse.svg" alt="shadow ellipse background"/>
                                <h1 className=" text-center	 place-content-center text-[#fff] not-italic font-bold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[48px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                    HK680-HK150 Vertical Injection Moulding <span className="underlinecss italic ">Machine  </span>     
                                </h1>
                    </div>
                    
                    
                    
                    <div className=" z-50   grid col-span-1   h-[300px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  	 ">
                                     
                         <iframe 
                            className="h-full w-full"
                            src="https://www.youtube.com/embed/Zav9PZyOLRY?si=1AVIIix2qAi6hnTF" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>
                    
                    </div>
                    
                    

                    {/* <div className=" mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">

                            <div className=" slider-container flex  md:justify-between md:place-content-between  place-content-center   ">
                                <Slider {...settings} className=" place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  ">
                                
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 2.jpg" alt="HK680-HK150 Vertical Injection Moulding one "/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 2.jpg" alt="HK680-HK150 Vertical Injection Moulding two"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 2.jpg" alt="HK680-HK150 Vertical Injection Moulding three"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 2.jpg" alt="HK680-HK150 Vertical Injection Moulding four"/>
                                     
                                                                   
                                </Slider>
                            </div>
                    </div> */}


                    <div className=" border mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">
                                        
                    <Swiper
                        spaceBetween={20}
                        centeredSlides={true}
                        autoplay={{delay: 2500, disableOnInteraction: false,}}
                        pagination={{clickable: true,}}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className=" mySwiper flex  md:justify-between md:place-content-between  place-content-center "
                    >                           
                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 2.jpg" alt="HK680-HK150 Vertical Injection Moulding one "/></SwiperSlide>
                            <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 2_2.jpg" alt="HK680-HK150 Vertical Injection Moulding two "/></SwiperSlide>
                            {/* <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "><img className="h-full w-full" src="images/hydraulic machine  product image 2_3.jpg" alt="HK680-HK150 Vertical Injection Moulding three "/></SwiperSlide> */}
                    </Swiper>
                                        
                    </div>


                </div>
            </div>
        </div>
         


       <div className="bg-black py-10 md:py-20">
                
                    
                
            <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">

                   <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-5 lg:gap-5 xl:gap-10 ">

                        <div className="relative grid col-span-1 md:col-span-2 border p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                            <img className="invisible md:visible absolute top-1/2 right-0 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=" background shadow"/>
                                <h2 className=" text-center	 place-content-center text-[#fff] not-italic font-semibold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                      <span className="underlinecss">Description </span>    
                                </h2>

                                <p className="text-[#fff]  font-medium text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-[30px] pt-8">
                                    The HK680-HK150 Vertical Injection Moulding Machine offers precise performance for various molding applications. Its vertical design optimizes space and simplifies mold loading and unloading. With consistent injection accuracy, it produces high-quality products across industries like automotive and electronics.

                                    The user-friendly control panel facilitates easy operation, while its durable construction ensures reliability. This machine enhances productivity and reduces operational costs, making it a versatile and efficient solution for modern manufacturing.
                                </p>

                        </div>


                        <div className="  relative grid col-span-1 md:col-span-1  border justify-items-center  p-4 md:p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                             {/* <img className="invisible	 md:visible z-100 absolute top-1/2 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=""/> */}
                                <h2 className="mb-5 md:mb-0 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[64px] ">
                                     <span className="underlinecss "> Only Rupee </span> 
                                </h2>
                                <h2 className="mb-5 md:mb-10 mt-0 md:mt-10 text-center	   text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[1px] ">
                                     <span className="">1,99,999 /- </span>
                                </h2>

                                <Link to="tel:+91 8090209080">
                                <button class="z-100 w-[200px] h-13 md:w-[200px] md:h-14 flex gap-3 bg-[#e3242b]  p-[12px_28px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-[red] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300         
                                                 hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                        <img className="-mx-1 w-[28px] h-[28px] " src="images/phone.svg" alt="phone"/>
                                                <span
                                                    
                                                    className="text-[#ffff] tracking-[0.05em] font-semibold text-[20px]"> 
                                                        Book Now
                                                </span>
                                </button>
                                </Link>
                        </div>

                   </div>

            </div>

       </div> 



        <div className="bg-black  py-10">

                <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">
                                <h2 className="mb-5 md:mb-10 lg:mb-20 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[40px] capitalize md:leading-[40px] lg:leading-[10px] ">
                                     <span className="underlinecss "> Product Specification </span> 
                                </h2>
                    <div className="grid grid-cols-1    ">

                        <div className=" grid col-span-1 border ">

                                <div className=" grid  ">
                                            <table className="table text-[#fff]   ">

                                                {/* head */}
                                                <thead className=" ">
                                                    <tr className="  border text-[22px]   ">
                                                        <td className="  text-center  text-[red] font-bold p-2">Sr. No.</td>
                                                        <td className="border text-[red] font-bold p-2">Features Title	</td>
                                                        <td className="border text-[red] font-bold p-2">Features Value</td>
                                                         
                                                    </tr>
                                                </thead>

                                                <tbody  className="">

                                                {/* row 1 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">1.</th>
                                                        <td className="py-2 px-2 border-r border-b">model</td>
                                                        <td className="py-2 px-2 border-b">	Vertical Plastic Injection Moulding Machine (HK680-HK150)</td>
                                                    </tr>

                                                {/* row 2 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">2.</th>
                                                        <td className="py-2 px-2 border-r border-b">Material</td>
                                                        <td className="py-2 px-2 border-b">	Mild Steel</td>  
                                                    </tr>

                                                {/* row 3 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">3.</th>
                                                        <td className="py-2 px-2 border-r border-b">Clamping Force</td>
                                                        <td className="py-2 px-2 border-b">12 Tonnes</td>
                                                    </tr>

                                                {/* row 4 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">4.</th>
                                                        <td className="py-2 px-2 border-r border-b">Automation Grad</td>
                                                        <td className="py-2 px-2 border-b">Semi Automatic</td>
                                                    </tr>

                                                {/* row 5 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">5.</th>
                                                        <td className="py-2 px-2 border-r border-b">Weight</td>
                                                        <td className="py-2 px-2 border-b">	680 Kg</td>  
                                                    </tr>

                                                {/* row 6 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">6.</th>
                                                        <td className="py-2 px-2 border-r border-b">Diameter</td>
                                                        <td className="py-2 px-2 border-b">W-685.80mm,L1519mm H2240mm </td>
                                                    </tr>
                                                    
                                                {/* row 7 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">7.</th>
                                                        <td className="py-2 px-2 border-r border-b">Electricity Bill</td>
                                                        <td className="py-2 px-2 border-b">20/hour</td>
                                                    </tr>

                                                {/* row 8 */}
                                                    <tr className="h-full ">
                                                        <th className="py-2  border-r border-b">8.</th>
                                                        <td className="py-2 px-2 border-r border-b">Heating	</td>
                                                        <td className="py-2 px-2 border-b">28-400 digree</td>  
                                                    </tr>

                                                {/* row 9 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">9.</th>
                                                        <td className="py-2 px-2 border-r border-b">Horizontol Opening cyliender</td>
                                                        <td className="py-2 px-2 border-b">	350 mm</td>
                                                    </tr>

                                                {/* row 10 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">10.</th>
                                                        <td className="py-2 px-2 border-r border-b">Injection Pressure</td>
                                                        <td className="py-2 px-2 border-b">12 ton</td>
                                                    </tr>

                                                {/* row 11 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">11.</th>
                                                        <td className="py-2 px-2 border-r border-b">Injection Short Capacity/shot. up & down</td>
                                                        <td className="py-2 px-2 border-b">80 gm</td>  
                                                    </tr>

                                                {/* row 12 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">12.</th>
                                                        <td className="py-2 px-2 border-r border-b">Length Of material (min.to max.)</td>
                                                        <td className="py-2 px-2 border-b">2mm To 250mm</td>
                                                    </tr>

                                                {/* row 13 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">13.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine Warranty</td>
                                                        <td className="py-2 px-2 border-b">one year (12months.)</td>
                                                    </tr>

                                                {/* row 14 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">14.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine Weight</td>
                                                        <td className="py-2 px-2 border-b">	680kg approx.</td>  
                                                    </tr>

                                                {/* row 15 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">15.</th>
                                                        <td className="py-2 px-2 border-r border-b">machines buyback option</td>
                                                        <td className="py-2 px-2 border-b">yes with warranty we purchase buyback (t&c apply.)</td>
                                                    </tr>

                                                
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">16.</th>
                                                        <td className="py-2 px-2 border-r border-b">Material</td>
                                                        <td className="py-2 px-2 border-b">Plastic C pvc,U pvc</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">17.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max Capacity</td>
                                                        <td className="py-2 px-2 border-b">40mm plunger with 0-15kg/hour capacity</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">18.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max. Shot Weight</td>
                                                        <td className="py-2 px-2 border-b">	80-90gms</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">19.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.hydraulic Pressure</td>
                                                        <td className="py-2 px-2 border-b">108 kg/cm2</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.Mold Thickness Capacity (LxTxH)</td>
                                                        <td className="py-2 px-2 border-b">253 mm</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">21.</th>
                                                        <td className="py-2 px-2 border-r border-b">Mould Dimensions L x Wx H</td>
                                                        <td className="py-2 px-2 border-b">	L1519MM x W685.80MM x H2240MM.</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">22.</th>
                                                        <td className="py-2 px-2 border-r border-b">Oil Tank Capacity</td>
                                                        <td className="py-2 px-2 border-b">60 litres</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">23.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.Mold Thickness Capacity (LxTxH)</td>
                                                        <td className="py-2 px-2 border-b">253 mm</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">24.</th>
                                                        <td className="py-2 px-2 border-r border-b">Operation Man Power</td>
                                                        <td className="py-2 px-2 border-b">1 Labour</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">25.</th>
                                                        <td className="py-2 px-2 border-r border-b">operation requrement</td>
                                                        <td className="py-2 px-2 border-b">single phase 220volt 1kw electricity</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">26.</th>
                                                        <td className="py-2 px-2 border-r border-b">Plunger Diameter</td>
                                                        <td className="py-2 px-2 border-b">40 mm</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">27.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Consumption</td>
                                                        <td className="py-2 px-2 border-b">1-2KWh Single Phase</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">28.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Source</td>
                                                        <td className="py-2 px-2 border-b">220volt single phase electricit</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">29.</th>
                                                        <td className="py-2 px-2 border-r border-b">Product Type</td>
                                                        <td className="py-2 px-2 border-b">injection moulding machine</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r  ">30.</th>
                                                        <td className="py-2 px-2 border-r  ">Production Capacity</td>
                                                        <td className="py-2 px-2  ">15-16kg /Hour</td>
                                                    </tr>

                                                 
                                                </tbody>
                                            </table>
                                </div>

                        </div>

                        <div className=" grid col-span-1 border">


                        </div>

                    </div>

                </div>

        </div>

    










        </div>

    );
}
export default HK680HK150VerticalInjectionMouldingMachine;