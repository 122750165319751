import React from "react";
import { Helmet } from 'react-helmet';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const HydraulicStacker = () => {


    // const settings = 
    //     {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     arrows: false,
    //     }


return (

    <div className=" bg-black">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content=' Discover high-quality hydraulic stackers designed for efficient material handling, 
                                lifting, and stacking in warehouses and industrial settings. 
                                Our heavy-duty hydraulic stackers are perfect for moving heavy loads with ease and precision.'
                />

                <meta name='keywords' 
                      content=' Hydraulic Stacker Machine, Industrial Hydraulic Stacker, Manual Hydraulic Stacker, Electric Hydraulic Stacker, Hydraulic Lift Stacker, Heavy Duty Hydraulic Stacker, Hydraulic Stacker for Warehouse, Hydraulic Pallet Stacker, Hydraulic Stacker Equipment, Hydraulic Stacker for Material Handling,
                                Hydraulic Stacker for Industrial Use, High-Capacity Hydraulic Stacker, Electric Powered Hydraulic Stacker, Hydraulic Stacker for Loading and Unloading, Hydraulic Stacker Lift for Warehouse Operations, Hydraulic Stacker for Heavy Loads, Hydraulic Pallet Lifter, Hydraulic Stacker Forklift, 
                                Ergonomic Hydraulic Stacker, Hydraulic Material Handling Stacker, Hydraulic Stacker for Warehouses, Hydraulic Fork Stacker, Pallet Hydraulic Stacker, Heavy Duty Hydraulic Lift Stacker, Hydraulic Material Handling Equipment, Hydraulic Stacker for Loading and Unloading, Compact Hydraulic Stacker, 
                                Electric Hydraulic Stacker Lift, Hydraulic Stacker with Adjustable Forks, Hydraulic Stacker for Palletized Loads, Warehouse Hydraulic Stacker, Hydraulic Lifting Stackers for Heavy Loads, 
                                Hydraulic Stacker for Stack and Transport, Portable Hydraulic Stacker, Manual Hydraulic Stacker for Small Spaces, Heavy Load Hydraulic Stacker, Hydraulic Stacker for Industrial Applications, 
                                Hydraulic Pallet Lifter Stacker, Hydraulic Stacker for Storage Systems, Hydraulic Stacker with Electric Motor, Durable Hydraulic Stacker Equipment, Adjustable Hydraulic Stacker for Warehouse, 
                                Hydraulic Stacker for Material Lifting and Handling, High-Capacity Hydraulic Stacker for Heavy Materials, Forklift Hydraulic Stacker for Transportation '        
                />
                <title>Heavy Duty Hydraulic Stacker for Efficient Material Handling & Warehouse Operations | Hydroking Industries</title>
                <link rel="canonical" href="https://www.hydrokings.in/Hydraulic-Stacker" />

                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Heavy Duty Hydraulic Stacker for Efficient Material Handling & Warehouse Operations | Hydroking Industries" />
                <meta property="og:description" content="Discover premium hydraulic stackers ideal for material handling, lifting, and stacking in warehouses and industrial settings. High-capacity hydraulic stackers for heavy load movement and storage." />
                
                {/* Open Graph Image for Social Media Sharing */}
                <meta property="og:image" content="images/hydraulic machine  product image 5.jpg" /> {/* Replace with your actual image URL */}
                <meta property="og:image:alt" content="Hydraulic Stacker for Material Handling" />
                
                {/* Image Dimensions (Optional, recommended for social media optimization) */}
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                {/* URL for Sharing */}
                <meta property="og:url" content="https://www.hydrokings.in/Hydraulic-Stacker" /> {/* Replace with your page URL */}
                
                {/* Open Graph Type and Site Name */}
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Hydroking Industries" />
            </Helmet>
       

        <div className=" bg-black relative py-10 ">
          {/* <img class="absolute invisible	 sm:visible top-0 left-0 w-44 md:w-1/6 -scale-x-100 " src="images/circle-triple.svg" alt="" /> */}
            <div className=" mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px]   place-content-center  ">

                <div className="  md:flex  md:justify-between place-content-center  ">
                
                    
                   
                    <div className=" relative grid col-span-1 place-content-center   h-[120px] w-full  md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]   "> 
                         <img class="absolute invisible	 md:visible top-1/6 opacity-50  top-[10%]  md:w-full  " src="images/circle-triple.svg" alt=" circle background" />
                         <img className="absolute invisible	 md:visible  top-0 md:top-0 left-0 transform  " src="images/shadow-ellipse.svg" alt="shadow background"/>
                                <h1 className=" text-center	 place-content-center text-[#fff] not-italic font-bold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[48px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                    Hydraulic Stacker <span className="underlinecss italic ">Machine  </span>     
                                </h1>
                    </div>
                    
                    
                    
                    <div className=" z-50   grid col-span-1   h-[300px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  	 ">
                                     
                         <iframe 
                            className="h-full w-full"
                            src="https://www.youtube.com/embed/Zav9PZyOLRY?si=1AVIIix2qAi6hnTF" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>
                    
                    </div>
                    
                    

                    {/* <div className=" mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">

                            <div className=" slider-container flex  md:justify-between md:place-content-between  place-content-center   ">
                                <Slider {...settings} className=" place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  ">
                                
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 5.jpg" alt="Hydraulic Stacker one"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 5.jpg" alt="Hydraulic Stacker two"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 5.jpg" alt="Hydraulic Stacker three"/>
                                    <img className="h-full w-full" src="images/hydraulic machine  product image 5.jpg" alt="Hydraulic Stacker four"/>
                                     
                                                                   
                                </Slider>
                            </div>
                    </div> */}


                                    <div className=" border mt-8 sm:mt-0 place-content-center  md:place-content-between grid col-span-1   h-[310px] w-full md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px]  xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]    ">
                                        
                                        <Swiper
                                            spaceBetween={20}
                                            centeredSlides={true}
                                            autoplay={{delay: 2500, disableOnInteraction: false,}}
                                            pagination={{clickable: true,}}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className=" mySwiper flex  md:justify-between md:place-content-between  place-content-center "
                                        >                           
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 5.jpg" alt="Hydraulic Stacker one"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 5_2.jpg" alt="Hydraulic Stacker two"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 5_3.jpg" alt="Hydraulic Stacker three"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 5_4.jpg" alt="Hydraulic Stacker four"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 5_5.jpg" alt="Hydraulic Stacker five"/> </SwiperSlide>
                                                <SwiperSlide className="place-items-center md:place-items-between  h-full w-[310px] md:h-[250px]  md:w-[250px] lg:h-[300px]  lg:w-[300px] xl:h-[350px]  xl:w-[350px]  2xl:h-[400px]  2xl:w-[400px]  "> <img className="h-full w-full" src="images/hydraulic machine  product image 5_6.jpg" alt="Hydraulic Stacker six"/> </SwiperSlide>
                                        </Swiper>
                                                            
                                    </div>


                </div>
            </div>
        </div>
         


       <div className="bg-black py-10 md:py-20">
                
                    
                
            <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">

                   <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-5 lg:gap-5 xl:gap-10 ">

                        <div className="relative grid col-span-1 md:col-span-2 border p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                            <img className="invisible md:visible absolute top-1/2 right-0 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt="shadow background"/>
                                <h2 className=" text-center	 place-content-center text-[#fff] not-italic font-semibold text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[62px] ">
                                      <span className="underlinecss">Description </span>    
                                </h2>

                                <p className="text-[#fff]  font-medium text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-[30px] pt-8">
                                    A hydraulic stacker is a versatile material handling device designed to lift, move, and stack goods efficiently using hydraulic power. It features a hydraulic lift system that allows for smooth and effortless raising and lowering of loads, accommodating various pallet sizes. With a compact design for easy maneuverability, hydraulic stackers are ideal for warehouses, manufacturing facilities, and retail environments. They come in various load capacities and can be operated manually or electrically. This equipment enhances productivity by facilitating efficient organization and movement of materials while ensuring safety and ease of use.
                                </p>

                        </div>


                        <div className="  relative grid col-span-1 md:col-span-1  border justify-items-center  p-4 md:p-5 rounded-[25px] backdrop-saturate-200 bg-white/20">
                             {/* <img className="invisible	 md:visible z-100 absolute top-1/2 transform -translate-y-1/2" src="images/shadow-ellipse.svg" alt=""/> */}
                                <h2 className="mb-5 md:mb-0 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[64px] ">
                                     <span className="underlinecss "> Only Rupee </span> 
                                </h2>
                                <h2 className="mb-5 md:mb-10 mt-0 md:mt-10 text-center	   text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[45px] capitalize md:leading-[40px] lg:leading-[1px] ">
                                     <span className="">42,000 /- </span>
                                </h2>

                                <Link to="tel:+91 8090209080">
                                <button class="z-100 w-[200px] h-13 md:w-[200px] md:h-14 flex gap-3 bg-[#e3242b]  p-[12px_28px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-[red] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300         
                                                 hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                        <img className="-mx-1 w-[28px] h-[28px] " src="images/phone.svg" alt="phone"/>
                                                <span
                                                    
                                                    className="text-[#ffff] tracking-[0.05em] font-semibold text-[20px]"> 
                                                        Book Now
                                                </span>
                                </button>
                                </Link>
                        </div>

                   </div>

            </div>

       </div> 



        <div className="bg-black  py-10">

                <div className="mx-6 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] ">
                                <h2 className="mb-5 md:mb-10 lg:mb-20 text-center	text-[#fff] not-italic font-medium text-[26px] md:text-[25px] lg:text-[32px] xl:text-[41px] 2xl:text-[40px] capitalize md:leading-[40px] lg:leading-[10px] ">
                                     <span className="underlinecss "> Product Specification </span> 
                                </h2>
                    <div className="grid grid-cols-1    ">

                        <div className=" grid col-span-1 border ">

                                <div className=" grid  ">
                                            <table className="table text-[#fff]   ">

                                                {/* head */}
                                                <thead className=" ">

                                                    <tr className="  border text-[22px]   ">
                                                        <td className="  text-center  text-[red] font-bold p-2">Sr. No.</td>
                                                        <td className="border text-[red] font-bold p-2">Features Title	</td>
                                                        <td className="border text-[red] font-bold p-2">Features Value</td>
                                                    </tr>
                                                </thead>

                                                <tbody  className="">

                                                {/* row 1 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">1.</th>
                                                        <td className="py-2 px-2 border-r border-b">model</td>
                                                        <td className="py-2 px-2 border-b">Hydraulic Manual Hand Stacke</td>
                                                    </tr>

                                                {/* row 2 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">2.</th>
                                                        <td className="py-2 px-2 border-r border-b">Material</td>
                                                        <td className="py-2 px-2 border-b">Mild Steel</td>  
                                                    </tr>

                                                {/* row 3 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">3.</th>
                                                        <td className="py-2 px-2 border-r border-b">Lifting height</td>
                                                        <td className="py-2 px-2 border-b">1600 mm</td>
                                                    </tr>

                                                {/* row 4 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">4.</th>
                                                        <td className="py-2 px-2 border-r border-b">Length Of material (min.to max.)</td>
                                                        <td className="py-2 px-2 border-b">2mm To 2000mm, 6feet, 72inch</td>
                                                    </tr>

                                                {/* row 5 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">5.</th>
                                                        <td className="py-2 px-2 border-r border-b">Lifting Capacity</td>
                                                        <td className="py-2 px-2 border-b">3000kg (3Ton)</td>  
                                                    </tr>

                                                {/* row 6 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">6.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine self Weight</td>
                                                        <td className="py-2 px-2 border-b">	89kg approx</td>
                                                    </tr>
                                                    
                                                {/* row 7 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">7.</th>
                                                        <td className="py-2 px-2 border-r border-b">Machine Warranty</td>
                                                        <td className="py-2 px-2 border-b">one year (12months)</td>
                                                    </tr>

                                                {/* row 8 */}
                                                    <tr className="h-full ">
                                                        <th className="py-2  border-r border-b">8.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max Capacity</td>
                                                        <td className="py-2 px-2 border-b">40mm C ID with 0-3000kg capacity</td>  
                                                    </tr>

                                                {/* row 9 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">9.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.hydraulic Pressure</td>
                                                        <td className="py-2 px-2 border-b">100 kg/cm2</td>
                                                    </tr>

                                                {/* row 10 */}
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">10.</th>
                                                        <td className="py-2 px-2 border-r border-b">oil tank capacity</td>
                                                        <td className="py-2 px-2 border-b">50litres </td>
                                                    </tr>

                                                {/* row 11 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">11.</th>
                                                        <td className="py-2 px-2 border-r border-b">Operation Man Power</td>
                                                        <td className="py-2 px-2 border-b">1 Labour</td>  
                                                    </tr>

                                                {/* row 12 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">12.</th>
                                                        <td className="py-2 px-2 border-r border-b">Size</td>
                                                        <td className="py-2 px-2 border-b">W762MM x 2133MM x H2438MM</td>
                                                    </tr>

                                                {/* row 13 */}
                                                <tr  >
                                                        <th className="py-2 border-r border-b">13.</th>
                                                        <td className="py-2 px-2 border-r border-b">Weight Kg	</td>
                                                        <td className="py-2 px-2 border-b">self weight.-89kg (approx)</td>
                                                    </tr>

                                                {/* row 14 */}
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">14.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Consumption	</td>
                                                        <td className="py-2 px-2 border-b">No electricity need</td>  
                                                    </tr>

                                                {/* row 15 */}
                                                    <tr>
                                                        <th className="py-2 border-r border-b">15.</th>
                                                        <td className="py-2 px-2 border-r border-b">Product self weight (in kgs.)</td>
                                                        <td className="py-2 px-2 border-b">89kg (approx)</td>
                                                    </tr>

                                                
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">16.</th>
                                                        <td className="py-2 px-2 border-r border-b">Self Weight	</td>
                                                        <td className="py-2 px-2 border-b">80 Kg</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">17.</th>
                                                        <td className="py-2 px-2 border-r border-b">Clamping Force</td>
                                                        <td className="py-2 px-2 border-b">3 Tonnes</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">18.</th>
                                                        <td className="py-2 px-2 border-r border-b">colour</td>
                                                        <td className="py-2 px-2 border-b">Yellow, skyblue, red, orange</td>
                                                    </tr>

                                                 
                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">19.</th>
                                                        <td className="py-2 px-2 border-r border-b">cyliender Capacity up & down</td>
                                                        <td className="py-2 px-2 border-b">25mm-1600mm</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">20.</th>
                                                        <td className="py-2 px-2 border-r border-b">Cyliender ID Diameter</td>
                                                        <td className="py-2 px-2 border-b">40 mm</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">21.</th>
                                                        <td className="py-2 px-2 border-r border-b">Handle Length</td>
                                                        <td className="py-2 px-2 border-b">3feet, 36inch, 914.40mm</td>
                                                    </tr>

                                                 
  {/*                                               <tr className="hover">
                                                        <th className="py-2 border-r border-b">22.</th>
                                                        <td className="py-2 px-2 border-r border-b">Space Between Columns</td>
                                                        <td className="py-2 px-2 border-b">850 x 300 mm (LR x FB)</td>  
                                                    </tr> 


                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">23.</th>
                                                        <td className="py-2 px-2 border-r border-b">Max.Mold Thickness Capacity (LxTxH)</td>
                                                        <td className="py-2 px-2 border-b">253 mm</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">24.</th>
                                                        <td className="py-2 px-2 border-r border-b">Operation Man Power</td>
                                                        <td className="py-2 px-2 border-b">1 Labour</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">25.</th>
                                                        <td className="py-2 px-2 border-r border-b">operation requrement</td>
                                                        <td className="py-2 px-2 border-b">single phase 220volt 1kw electricity</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">26.</th>
                                                        <td className="py-2 px-2 border-r border-b">Plunger Diameter</td>
                                                        <td className="py-2 px-2 border-b">40 mm</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">27.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Consumption</td>
                                                        <td className="py-2 px-2 border-b">1-2KWh Single Phase</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r border-b">28.</th>
                                                        <td className="py-2 px-2 border-r border-b">Power Source</td>
                                                        <td className="py-2 px-2 border-b">220volt single phase electricit</td>
                                                    </tr>

                                                    <tr className="hover">
                                                        <th className="py-2 border-r border-b">29.</th>
                                                        <td className="py-2 px-2 border-r border-b">Product Type</td>
                                                        <td className="py-2 px-2 border-b">injection moulding machine</td>  
                                                    </tr>
                                                
                                                 
                                                    <tr  >
                                                        <th className="py-2 border-r  ">30.</th>
                                                        <td className="py-2 px-2 border-r  ">Production Capacity</td>
                                                        <td className="py-2 px-2  ">15-16kg /Hour</td>
                                                    </tr>
 */}
                                                 
                                                </tbody>
                                            </table>
                                </div>

                        </div>

                        <div className=" grid col-span-1 border">


                        </div>

                    </div>

                </div>

        </div>

    










        </div>

    );
}
export default HydraulicStacker;