import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
// import AboutPageSection1 from '../screens/ComponentsPages/AboutPageSection1'
// import GalleryPage from '../screens/ComponentsPages/GalleryPage'
// import AboutCallPage from '../screens/ComponentsPages/AboutCallPage'

// const AboutPageSection1 = React.lazy(()=> import('../screens/ComponentsPages/AboutPageSection1'));
// const GalleryPage = React.lazy(()=> import('../screens/ComponentsPages/GalleryPage'));

const GalleryPageImages1 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesOne'));
const GalleryPageImages2 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesTwo'));
const GalleryPageImages3 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesThree'));
const GalleryPageImages4 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesFour'));
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));



const Gallery  = () => {
    return (
        <div className='relative'>

        <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'           
                />
                <meta name='keywords' 
                      content=' Hydraulic Machine, CNC Die Molds, VMC Die Molds, Injection Molding Machine, Molding Machine, Moulding, Moulding Machine, Mold, Die, Vertical Injection Molding Machine, Horizontal Injection Molding Machine, Blow Molding Machine, 
                                CNC Machine, VMC Machine, Cup Making Machine, Plate Making Machine, Manufacturer, Hydroking, Hydroking Industries, Plastic Injection Machine, Plastic Machine, Toy Making Machine, Plastic Machine, Plastic Molding Machine, 
                                cnc job work, vmc job work,Horizontal Plastic Injection Molding Machine, Hydraulic Stacker, Hand Pallet Truck, Power Press Machine, Hand Plate Machine, Stacker Machine, Hydraulic Vertical Injection Molding Machine, Plastic Vertical Injection Machine,
                                Vertical Injection Moulding Machine Manufacturer, Vertical Injection Moulding Machine Price, Automatic Vertical Injection Molding Machine, Small Vertical Injection Molding Machine, Electric Vertical Injection Molding Machine, Best Vertical Injection Molding Machine in India,
                                 Cheap Vertical Injection Molding Machine for Sale, Fully Automatic Vertical Injection Molding Machine, Vertical Injection Molding Machine for PVC Products, Low-Cost Vertical Injection Moulding Machine, Vertical Plastic Injection Machine with PLC Control, 
                                 Vertical Injection Machine for Rubber and Silicone, Vertical Injection Machine for Automotive Parts, Vertical Injection Machine for Medical Equipment, Vertical Injection Molding for Electrical Components, Vertical Injection Molding for Mobile Charger Parts, Vertical Injection Machine for LED Bulb Holder,
                                 junction box making injection moulding machine, Bucket Making Machine, Electrical junction box making injection moulding machine '        
                />
                <title>Gallery Page : HYDRAULIC MACHINE PHOTOS, DIE-MOLDS PHOTOS </title>
                <link rel="canonical" href="https://www.hydrokings.in/gallery" />
          </Helmet>

            <Suspense fallback={<div>Loading...</div>}>

            <div className='bg-purpleHeader'>
                 
                {/* <AboutPageSection1/>
                <GalleryPage/>
                 
                <AboutCallPage/> */}
                 
                <GalleryPageImages1/>
                <GalleryPageImages2/>
                <GalleryPageImages3/>
                <GalleryPageImages4/>
                <HoverImageLinks/>


            </div>
        </Suspense>

        </div>

        );
    }
export default Gallery