import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
 

const ContactBanner = React.lazy(()=> import('../screens/ComponentsPages/ContactBanner'));
const WavyBackgroundPage = React.lazy(()=> import('./ComponentsPages/HomeCallInfo')); 
const Form = React.lazy(()=> import('./ComponentsPages/HomeForm')); 
const HomeFormPage = React.lazy(()=> import('../screens/ComponentsPages/HomeFormPage'));


  
const ContactUs  = () => {
    return (
        <div className='relative'> 

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Our Phone Number : 8090209080, 9090434242 
                               Our Address : Near Motiyar Dharam Kanta, Opposite Karni Riico, Pugal Road, Bikaner-334004 (Rajasthan), India'
                               
                />
                <meta name='keywords' 
                      content=' Hydraulic Machine, CNC Die Molds, VMC Die Molds, Injection Molding Machine, Molding Machine, Moulding, Moulding Machine, Mold, Die, Vertical Injection Molding Machine, Horizontal Injection Molding Machine, Blow Molding Machine, 
                                CNC Machine, VMC Machine, Cup Making Machine, Plate Making Machine, Manufacturer, Hydroking, Hydroking Industries, Plastic Injection Machine, Plastic Machine, Toy Making Machine, Plastic Machine, Plastic Molding Machine, 
                                cnc job work, vmc job work,Horizontal Plastic Injection Molding Machine, Hydraulic Stacker, Hand Pallet Truck, Power Press Machine, Hand Plate Machine, Stacker Machine, Hydraulic Vertical Injection Molding Machine, Plastic Vertical Injection Machine,
                                Vertical Injection Moulding Machine Manufacturer, Vertical Injection Moulding Machine Price, Automatic Vertical Injection Molding Machine, Small Vertical Injection Molding Machine, Electric Vertical Injection Molding Machine, Best Vertical Injection Molding Machine in India,
                                 Cheap Vertical Injection Molding Machine for Sale, Fully Automatic Vertical Injection Molding Machine, Vertical Injection Molding Machine for PVC Products, Low-Cost Vertical Injection Moulding Machine, Vertical Plastic Injection Machine with PLC Control, 
                                 Vertical Injection Machine for Rubber and Silicone, Vertical Injection Machine for Automotive Parts, Vertical Injection Machine for Medical Equipment, Vertical Injection Molding for Electrical Components, Vertical Injection Molding for Mobile Charger Parts, Vertical Injection Machine for LED Bulb Holder,
                                 junction box making injection moulding machine, Bucket Making Machine, Electrical junction box making injection moulding machine  '        
                />
                <title>Contact Us : Call Now to Book Hydraulic Machines</title>
                <link rel="canonical" href="https://www.hydrokings.in/contactUs" />
            </Helmet>


        <Suspense fallback={<div>Loading...</div>}>
            
                <ContactBanner/>
                <WavyBackgroundPage/>
                <Form/>
                <HomeFormPage/> 

             
         </Suspense>
        </div>

        );
    }
export default ContactUs